import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Heading,
  Image,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';

import { createClient } from '@supabase/supabase-js';
import React from 'react';
const Waitlist = () => {
  const supabase = createClient(
    'https://KEY.supabase.co',
    'KEY'
  );

  const [email, setEmail] = useState(null);

  const validateEmail = email => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleJoinWaitlist = async () => {
    console.log(email);
    if (!validateEmail(email)) {
      alert('Please enter a valid email');
      return;
    }

    const { data, error } = await supabase.from('waitlist').insert([
      {
        email: email,
      },
    ]);

    if (error) {
      alert('Something went wrong. Please try again');
    } else {
      alert('Succes! W Rizz!');
    }
    console.log(data);
  };

  return (
    <VStack width="100%" alignContent="center">
      <Box alignContent="center" padding="20px">
        <VStack paddingLeft="20%" paddingRight="20%" alignContent="center">
          <Image
            src="https://i.ibb.co/w0PwrFg/rizzmockup.png"
            alt="Mockup of Rizz"
          />
          <Heading textAlign="center" color="white">
            Unleash the power of{' '}
            <Text
              as="span"
              bgGradient="linear(to-r, #EE796A, #F3B257)"
              bgClip="text"
            >
              infinite
            </Text>{' '}
            rizz!
          </Heading>
          {/* <form
            name="waitlist"
            method="post"
            data-netlify="true"
            onSubmit="submit"
          > */}
          {/* <input type="hidden" name="form-name" value="waitlist" /> */}
          <FormControl isRequired>
            <label htmlFor="email" color="white">
              Email address
            </label>
            <br />
            <Input
              id="email"
              type="email"
              name="email"
              color="white"
              onChange={e => {
                setEmail(e.target.value);
                console.log('hi');
              }}
            />
            <FormHelperText color="white">
              We'll never share your email, ever.
            </FormHelperText>
          </FormControl>
          <br />
          <Button
            type="submit"
            colorScheme="orange"
            width="100%"
            onClick={handleJoinWaitlist}
          >
            Join Waitlist
          </Button>
          {/* </form> */}
        </VStack>
      </Box>
    </VStack>
  );
};

export default Waitlist;
